// TODO: move this to `fe-config`
import uniq from 'lodash/fp/uniq';

import type { Locale } from '@change/config/locales';

type HomepageL10n = { lang: Locale; country_code?: string };
type HomepageL10nMap = Record<string, HomepageL10n>;
export const HOMEPAGE_L10N_MAP: HomepageL10nMap = {
	es: { lang: 'es-ES', country_code: 'ES' },
	fr: { lang: 'fr-FR', country_code: 'FR' },
	'en-au': { lang: 'en-AU', country_code: 'AU' },
	'en-ca': { lang: 'en-CA', country_code: 'CA' },
	'en-gb': { lang: 'en-GB', country_code: 'GB' },
	'en-in': { lang: 'en-IN', country_code: 'IN' },
	'en-us': { lang: 'en-US', country_code: 'US' },
	'es-ar': { lang: 'es-AR', country_code: 'AR' },
	ru: { lang: 'ru-RU', country_code: 'RU' },
	'pt-br': { lang: 'pt-BR', country_code: 'BR' },
	it: { lang: 'it-IT', country_code: 'IT' },
	tr: { lang: 'tr-TR', country_code: 'TR' },
	id: { lang: 'id-ID', country_code: 'ID' },
	ja: { lang: 'ja-JP', country_code: 'JP' },
	'es-la': { lang: 'es-419', country_code: undefined },
	th: { lang: 'th-TH', country_code: 'TH' },
	de: { lang: 'de-DE', country_code: 'DE' },
	hi: { lang: 'hi-IN', country_code: 'IN' },
} as const;
export const HOMEPAGE_LOCALES = Object.keys(HOMEPAGE_L10N_MAP);

export type SapL10n = { lang: Locale; country_code?: string; sap: string };
export type SapL10nMap = Record<string, SapL10n>;
export const SAP_L10N_MAP: SapL10nMap = {
	es: { lang: 'es-ES', country_code: 'ES', sap: 'iniciar-una-petición' },
	fr: { lang: 'fr-FR', country_code: 'FR', sap: 'lancer-une-pétition' },
	'en-au': { lang: 'en-AU', country_code: 'AU', sap: 'start-a-petition' },
	'en-ca': { lang: 'en-CA', country_code: 'CA', sap: 'start-a-petition' },
	'en-gb': { lang: 'en-GB', country_code: 'GB', sap: 'start-a-petition' },
	'en-in': { lang: 'en-IN', country_code: 'IN', sap: 'start-a-petition' },
	'en-us': { lang: 'en-US', country_code: 'US', sap: 'start-a-petition' },
	'es-ar': { lang: 'es-AR', country_code: 'AR', sap: 'iniciar-una-petición' },
	ru: { lang: 'ru-RU', country_code: 'RU', sap: 'создать-петицию' },
	'pt-br': { lang: 'pt-BR', country_code: 'BR', sap: 'inicie-uma-petição' },
	it: { lang: 'it-IT', country_code: 'IT', sap: 'lancia-una-petizione' },
	tr: { lang: 'tr-TR', country_code: 'TR', sap: 'kampanyanı-başlat' },
	id: { lang: 'id-ID', country_code: 'ID', sap: 'mulai-sebuah-petisi' },
	ja: { lang: 'ja-JP', country_code: 'JP', sap: 'スタート' },
	'es-la': { lang: 'es-419', sap: 'inizia-una-petizione' },
	th: { lang: 'th-TH', country_code: 'TH', sap: 'สร้างแคมเปญรณรงค์' },
	de: { lang: 'de-DE', country_code: 'DE', sap: 'eine-petition-starten' },
	// beta locales
	ph: { lang: 'tl-PH', country_code: 'PH', sap: 'magsimula-ng-petisyon' },
	ko: { lang: 'ko-KR', country_code: 'KR', sap: '캠페인-시작하기' },
} as const;
export const SAP_LOCALES = Object.keys(SAP_L10N_MAP);
export const SAP_SLUGS = uniq(Object.values(SAP_L10N_MAP).map(({ sap }) => sap));
export const SAP_SLUGS_ENCODED = SAP_SLUGS.map((slug) => encodeURIComponent(slug).toLowerCase());
